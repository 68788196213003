<template>
  <DashboardLayout>
    <div class="h-full base-container">
      <StickyTopbar title="Administration" @hide="hideTabs = $event">
        <template #tabs>
          <span class="ml-3">•</span>
          <SingleFilteringMenu
            v-if="hideTabs"
            :filters="[String(activeIndex)]"
            :filtering-options="tabsOnScroll"
            @change-filtering="saveTabInQuery($event[0])"
          ></SingleFilteringMenu>
        </template>
        <template #buttons>
          <Button class="p-button-text" @click="showCompanyInfoDialog = true">
            Manage company
          </Button>
          <CompanyInfoDialog
            v-model:open="showCompanyInfoDialog"
          ></CompanyInfoDialog>
        </template>
      </StickyTopbar>
      <main class="px-8">
        <TabView
          lazy
          :active-index="activeIndex"
          :class="{ 'hide-tabs': hideTabs }"
          @tab-change="saveTabInQuery($event.index)"
        >
          <TabPanel header="System"><SystemTab></SystemTab></TabPanel>
          <TabPanel header="Gitlab"><GitlabTab></GitlabTab></TabPanel>
          <TabPanel header="Bitbucket"><BitbucketTab></BitbucketTab></TabPanel>
          <TabPanel header="Jira"><JiraTab></JiraTab></TabPanel>
          <TabPanel header="Tempo"><TempoTab></TempoTab></TabPanel>
          <TabPanel header="Slack"><SlackTab></SlackTab></TabPanel>
        </TabView>
      </main>
    </div>
  </DashboardLayout>
</template>

<script setup lang="ts">
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import TabView from 'primevue/tabview/TabView.vue'
import TabPanel from 'primevue/tabpanel/TabPanel.vue'
import BitbucketTab from '@/components/admin-dashboard/bitbucket/BitbucketTab.vue'
import GitlabTab from '@/components/admin-dashboard/gitlab/GitlabTab.vue'
import TempoTab from '@/components/admin-dashboard/tempo/TempoTab.vue'
import JiraTab from '@/components/admin-dashboard/jira/JiraTab.vue'
import SlackTab from '@/components/admin-dashboard/slack/SlackTab.vue'
import SystemTab from '@/components/admin-dashboard/system/SystemTab.vue'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { isNil } from 'ramda'
import Button from '@/components/common/buttons/Button.vue'
import CompanyInfoDialog from '@/components/companies/CompanyInfoDialog.vue'
import StickyTopbar from '@/components/common/StickyTopbar.vue'
import SingleFilteringMenu from '@/components/common/filters/global/SingleFilteringMenu.vue'

const router = useRouter()
const store = useStore()
const showCompanyInfoDialog = ref(false)
const hideTabs = ref(false)
const activeIndex = computed(() => {
  return router.currentRoute.value.query.tab
    ? +router.currentRoute.value.query.tab
    : 0
})

const tabsOnScroll = [
  { name: 'System', id: 0 },
  { name: 'Gitlab', id: 1 },
  { name: 'Bitbucket', id: 2 },
  { name: 'Jira', id: 3 },
  { name: 'Tempo', id: 4 },
  { name: 'Slack', id: 5 },
]

const saveTabInQuery = (index: number) => {
  router.push({
    path: `/company/${store.getters['company/selectedCompanyId']}/administration`,
    query: {
      tab: index,
    },
  })
}

onBeforeMount(() => {
  store.dispatch('admin/getProjects')
  store.dispatch('admin/getUsers')
})

onMounted(() => {
  if (isNil(activeIndex.value)) {
    saveTabInQuery(0)
  }
})
</script>

<style lang="scss" scoped>
.base-container {
  max-height: 100vh;
  flex-grow: 1 !important;
  overflow: auto;
  background: white;
  padding-bottom: 32px;
}

.hide-tabs {
  & :deep(.p-tabview-nav-container) {
    @apply hidden transition-all;
  }
  & :deep(.p-tabview-panels) {
    @apply pt-5 transition-all;
  }
}
</style>
