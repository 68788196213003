import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export type CalendarWeekPhase = 'development' | 'on_hold' | 'maintenance'
export type CalendarWeekStatus = 'red' | 'yellow' | 'green'

export type PopupType = 'milestone' | 'calendar_week'

export interface MilestoneInfo {
  date: string
  description: string
  name: string
  released: boolean
  archived: boolean
  id: number
  release_id: number
  overdue: boolean
  start_date: string
  release_date: string
  note: string | null
  project: number
}

export interface MilestonePlanItem {
  date: string
  total_seconds_planned: number
}

export interface MilestoneReleaseItem {
  date: string
  description: string
  name: string
  released: boolean
  id: number
}

export interface WeeklyReportItem {
  date: string
  status: CalendarWeekStatus
}

export interface ProjectMilestones {
  original_project_id: number
  project_name: string
  plans: MilestonePlanItem[] | null
  releases: MilestoneReleaseItem[] | null
  weekly_reports: WeeklyReportItem[] | null
}

export interface WeekReport {
  date: string
  project: number
  status: string
  description: string
  budget: string
  phase: string
  id: number
  lead: {
    original_name: string | null
    avatar_url: string | null
  }
}

export interface MilestoneContributor {
  original_name: string
  avatar_url: string
}

export interface MilestoneProgress {
  count_all: number
  count_done: number
}

export interface MilestoneAdditionalInfo {
  assignees: MilestoneContributor[]
  issues_stats: MilestoneProgress
}

export interface MilestonesData {
  scale_type: string
  data: ProjectMilestones[]
}

type Context = ActionContext<MilestonesData, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<MilestonesData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/charts/milestones/`,
        { params }
      )
      .then(({ data }) => data)
  },
  getCalendarWeekReport(
    { rootGetters }: Context,
    params: { date: string; project: number }
  ): Promise<WeekReport> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/tempo/weekly-report/`,
        { params }
      )
      .then(({ data }) => data)
  },
  updateCalendarWeekData(
    { rootGetters }: Context,
    params: { date: string; project: number }
  ): Promise<WeekReport> {
    return axios
      .put(
        `api/companies/${rootGetters['company/selectedCompanyId']}/tempo/weekly-report/?date=${params.date}&project=${params.project}`,
        { ...params }
      )
      .then(({ data }) => data)
  },
  getMilestone({ rootGetters }: Context, id: number): Promise<MilestoneInfo> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/tempo/release/${id}`
      )
      .then(({ data }) => data)
  },
  updateMilestone(
    { rootGetters }: Context,
    params: { id: number; note: string }
  ): Promise<MilestoneInfo> {
    return axios
      .patch(
        `api/companies/${rootGetters['company/selectedCompanyId']}/tempo/release/${params.id}/`,
        { ...params }
      )
      .then(({ data }) => data)
  },
  getMilestoneAdditionalInfo(
    { rootGetters }: Context,
    payload: { release_id: number; project_id: number }
  ): Promise<MilestoneAdditionalInfo> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${payload.project_id}/charts/releases/${payload.release_id}/additional-info/`
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
