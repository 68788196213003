<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="$emit('close')"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">{{ drilldownData?.name }}</div>
    </template>
    <div class="text-xl font-semi-bold mb-4">Estimation accuracy</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="estimationData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.issue_key}: ${data.issue_name}` }}
        </Link>
      </template>
      <template #issue_estimated_time="{ data }">
        {{ convertSecondsToReadableWorkTime(data.issue_estimated_time) }}
      </template>
      <template #issue_total_spent_time="{ data }">
        {{ convertSecondsToReadableWorkTime(data.issue_total_spent_time) }}
      </template>
      <template #difference_amount="{ data }">
        <div
          :class="{
            'text-key-500': data.difference_amount < 0,
            'text-danger-500': data.difference_amount > 0,
          }"
          class="w-full h-full"
        >
          {{ calcDifferenceAmountText(data.difference_amount) }}
        </div>
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineProps, ref, watchEffect } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { showToastError, calcDifferenceAmountText } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import Link from '@/components/common/Link.vue'
import { Aggregate_By_Type } from '@/types/types'
import { convertSecondsToReadableWorkTime } from '../../../utils/date-utils'

const props = defineProps<{
  open: boolean
  drilldownData: { id: number; name: string } | null
  aggregation: Aggregate_By_Type
  filters: Filters
}>()

const estimationData = ref([])
const loading = ref(false)
const store = useStore()
const toast = useToast()
const scrollableTable = ref(true)

const columnsData = [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow: 2.5; padding-right: 10px',
  },
  {
    header: 'Original estimate',
    field: 'issue_estimated_time',
    is_sortable: true,
    use_template: true,
    styles: ' padding-right: 10px',
  },
  {
    header: 'Time spent',
    field: 'issue_total_spent_time',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px',
  },
  {
    header: 'Difference',
    field: 'difference_amount',
    is_sortable: true,
    use_template: true,
  },
]

watchEffect(() => {
  if (props.drilldownData) {
    getEstimationAccuracyDetails()
  }
})

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

async function getEstimationAccuracyDetails() {
  loading.value = true
  try {
    estimationData.value = await store.dispatch(
      'estimationAccuracy/getEstimationAccuracyDrilldownData',
      {
        ...activeFilters.value,
        [props.aggregation]: `${props.drilldownData?.id}`,
      }
    )
    loading.value = false
  } catch (e) {
    showToastError(toast, e)
    loading.value = false
  }
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>
