<template>
  <ChartWrapper
    :title="chartTitle.milestones"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations.milestones"
    :is-chart-data-empty="!responseData?.length"
    type="milestones"
    :customHeight="popup ? '234px' : '404px'"
    :icons="['jira']"
  >
    <template #subtitle>
      <MilestonesChartLegend />
    </template>
    <template #buttons>
      <Button
        v-if="!popup"
        size="small"
        class="p-button-text"
        @click="scrollToCurrentWeek('smooth')"
      >
        Current Week
      </Button>
    </template>
    <DataTable
      ref="milestonesTable"
      :value="tableData"
      scrollable
      scroll-height="320px"
      :loading="lazyLoading"
      class="p-datatable-sm milestones-table flex-1"
      :class="{
        'project-page': projectPage,
        'no-releases': projectPage && !tableData[0]?.release?.id,
        popup,
      }"
      @rowClick="
        projectPage &&
          clickOnReleases({
            event: $event.originalEvent,
            releases: [$event.data.release],
          })
      "
    >
      <template #empty>
        {{ projectPage ? 'No Milestones' : 'No Projects' }}</template
      >
      <Column
        :field="projectPage ? 'release_name' : 'project_name'"
        header="Calendar weeks"
        frozen
        :body-style="['width: 20%', 'minWidth: 200px', 'flexGrow: 0']"
        :header-style="['width: 20%', 'minWidth: 200px', 'flexGrow: 0']"
      >
        <template #body="slotProps">
          <div class="truncate pr-1">
            {{ slotProps.data[projectPage ? 'release_name' : 'project_name'] }}
          </div>
        </template>
      </Column>
      <Column
        v-for="(col, i) of columnsData"
        :key="`${col.field}-${i}`"
        :field="col.field"
        :body-style="['minWidth: 64px', col.styles]"
        :header-style="['minWidth: 64px']"
      >
        <template #header>
          <MilestoneTableHeaderCell
            :date="col.field"
            :table-data="tableData"
            :popup="popup"
            :project-page="projectPage"
            :selected-week-date="selectedWeekDate"
            @show-calendar-popup="showCalendarWeekPopup"
          ></MilestoneTableHeaderCell
        ></template>
        <template #body="slotProps">
          <MilestoneTableBodyCell
            :project-page="projectPage"
            :date="col.field"
            :body-data="slotProps.data"
            @show-calendar-popup="showCalendarWeekPopup"
            @click-on-releases="clickOnReleases"
          ></MilestoneTableBodyCell>
        </template>
      </Column>
    </DataTable>
    <MilestonePopup
      :open="!!milestonePopupData"
      :milestone-data="milestonePopupData"
      :project-page="projectPage"
      @close="milestonePopupData = null"
    ></MilestonePopup>
    <MilestoneCalendarWeekPopup
      :open="!!calendarWeekPopupData"
      :calendar-week-data="calendarWeekPopupData"
      :project-page="projectPage"
      :key="updateCalendarWeekPopup"
      @close="calendarWeekPopupData = null"
      @toggle-popups="togglePopups"
      @reload-milestone="reloadMilestone++"
    ></MilestoneCalendarWeekPopup>
    <ContextMenu
      ref="releasesContextMenu"
      :model="releasesContextMenuOptions"
    />
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import Button from '@/components/common/buttons/Button.vue'
import MilestonesChartLegend from '@/components/charts/milestones/MilestonesChartLegend.vue'
import DataTable from 'primevue/datatable/DataTable.vue'
import Column from 'primevue/column/Column.vue'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import {
  AGGREGATION_TYPE,
  FILTERS_DATE_FORMAT,
  MILESTONE_POPUPS,
} from '@/constants/constants'
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { Filters } from '@/store/modules/filters'
import {
  addWeeks,
  eachWeekOfInterval,
  format,
  getISOWeek,
  subWeeks,
  isSameWeek,
  addISOWeekYears,
  startOfISOWeekYear,
  endOfISOWeekYear,
  subISOWeekYears,
  endOfYear,
  startOfYear,
} from 'date-fns'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import MilestonePopup from '@/components/charts/milestones/MilestonePopup.vue'
import MilestoneCalendarWeekPopup from '@/components/charts/milestones/MilestoneCalendarWeekPopup.vue'
import ContextMenu from 'primevue/contextmenu/ContextMenu.vue'
import { useStore } from '@/store'
import {
  concat,
  symmetricDifferenceWith,
  prop,
  eqBy,
  uniqBy,
  clone,
  sortBy,
} from 'ramda'
import MilestoneTableHeaderCell from '@/components/charts/milestones/MilestoneTableHeaderCell.vue'
import MilestoneTableBodyCell from '@/components/charts/milestones/MilestoneTableBodyCell.vue'
import {
  MilestoneReleaseItem,
  PopupType,
  ProjectMilestones,
  WeeklyReportItem,
} from '@/store/modules/charts/milestones'

export interface MilestonePopupData {
  release_name: string
  project_id: number
  project_name: string
  release_id: number
  filters: Filters
}

export interface CalendarWeekPopupData {
  date: string
  project_id: number
  project_name: string
  filters: Filters
}

export interface DateColumnData {
  date: string
  original_project_id: number
  plans: number
  project_name: string
  releases: MilestoneReleaseItem[] | null
  weekly_reports: WeeklyReportItem | null
}

export interface ExtendedReleaseItem extends MilestoneReleaseItem {
  project_name: string
  project_id: number
}

const props = defineProps<{
  filters: Filters
  projectPage?: boolean
  popup?: boolean
  selectedWeekDate?: string
}>()

const emit = defineEmits<{
  (
    e: 'close-calendar-week-popup',
    value: {
      popup: PopupType
      data: MilestonePopupData | CalendarWeekPopupData
    }
  ): void
}>()

const store = useStore()
const lazyLoading = ref(false)
const milestonesTable = ref(null)

const releasesContextMenu = ref()
const releasesContextMenuOptions = ref<
  | {
      label: string
      id: number
      command: () => void
    }[]
  | null
>(null)

const milestonePopupData = ref<MilestonePopupData | null>(null)
const calendarWeekPopupData = ref<null>(null)

const filters = ref({
  ...props.filters,
  since: props.popup
    ? props.filters.since
    : format(startOfISOWeekYear(new Date()), FILTERS_DATE_FORMAT),
  until: props.popup
    ? props.filters.until
    : format(endOfISOWeekYear(new Date()), FILTERS_DATE_FORMAT),
  scale_type: AGGREGATION_TYPE.WEEK,
})

const { response, loading, fetchError } = useGettingChartData(
  {
    ...props,
    filters: filters.value,
  },
  chartTypes.Milestones
)

const tableWrapper = ref<HTMLElement | null>(null)
const reloadMilestone = ref(0)
const responseData = computed(() => response.value?.data)
const updateCalendarWeekPopup = ref(0)

const firstDay = ref(filters.value.since)

const lastDay = ref(filters.value.until)

const eachStepOfPeriod = computed(() => {
  const weeks = eachWeekOfInterval(
    {
      start: new Date(firstDay.value),
      end: new Date(lastDay.value),
    },
    { weekStartsOn: 1 }
  )
  return weeks.map((date) => format(date, FILTERS_DATE_FORMAT))
})

const calculateDatesForTableData = (project: ProjectMilestones) => {
  return eachStepOfPeriod.value.reduce((obj, date: string) => {
    return Object.assign(obj, {
      [date]: {
        ...project,
        date: date,
        plans:
          project.plans?.find((plan) => plan.date === date)
            ?.total_seconds_planned || 0,
        releases:
          (project.releases || [])
            .filter((release) =>
              isSameWeek(new Date(date), new Date(release.date))
            )
            .map((release) => ({
              ...release,
              project_id: project.original_project_id,
              project_name: project.project_name,
            })) || null,
        weekly_reports:
          project.weekly_reports?.find((report) => report.date === date) ||
          null,
      },
    })
  }, {})
}

const tableData = computed(() => {
  if (props.projectPage) {
    return (
      responseData.value[0].releases?.length
        ? responseData.value[0].releases
        : [{ name: 'No Milestones', id: 0 }]
    ).map((release: MilestoneReleaseItem) => {
      return {
        release_name: release.name,
        release: {
          ...release,
          project_id: responseData.value[0].original_project_id,
          project_name: responseData.value[0].project_name,
        },
        ...calculateDatesForTableData(responseData.value[0]),
      }
    })
  } else {
    return responseData.value.map((project: ProjectMilestones) => {
      return {
        project_name: project.project_name,
        ...calculateDatesForTableData(project),
      }
    })
  }
})

const columnsData = computed(() => {
  return eachStepOfPeriod.value.map((date) => ({
    header: `${format(new Date(date), 'MMM')} \n CW${getISOWeek(
      new Date(date)
    )}`,
    field: date,
  }))
})

const deepMergeMilestoneData = (
  currentData: ProjectMilestones[],
  lazyData: ProjectMilestones[]
) => {
  const uniqueProjects = clone(
    symmetricDifferenceWith(
      eqBy(prop('original_project_id')),
      currentData,
      lazyData
    )
  )
  const notUniqueCurrentProjects = clone(
    currentData.filter(
      (item) =>
        !uniqueProjects.some(
          (project) => project.original_project_id === item.original_project_id
        )
    )
  )
  const updatedProjects = notUniqueCurrentProjects.map(
    (project: ProjectMilestones) => {
      const lazyDataProject = lazyData.find((item: ProjectMilestones) => {
        return item.original_project_id === project.original_project_id
      })
      const plans = sortBy(
        prop('date'),
        uniqBy(
          prop('date'),
          concat(clone(project.plans || []), lazyDataProject?.plans || [])
        )
      )
      const releases = sortBy(
        prop('date'),
        uniqBy(
          prop('date'),
          concat(clone(project.releases || []), lazyDataProject?.releases || [])
        )
      )
      const weekly_reports = sortBy(
        prop('date'),
        uniqBy(
          prop('date'),
          concat(
            clone(project.weekly_reports || []),
            lazyDataProject?.weekly_reports || []
          )
        )
      )

      return {
        ...project,
        plans,
        releases,
        weekly_reports,
      }
    }
  )
  return sortBy(prop('project_name'), [...updatedProjects, ...uniqueProjects])
}

const loadNextYear = async () => {
  lazyLoading.value = true
  lastDay.value = format(
    addISOWeekYears(new Date(lastDay.value), 1),
    FILTERS_DATE_FORMAT
  )
  try {
    filters.value = {
      ...filters.value,
      since: format(
        new Date(startOfYear(new Date(lastDay.value))),
        FILTERS_DATE_FORMAT
      ),
      until: format(
        new Date(endOfYear(new Date(lastDay.value))),
        FILTERS_DATE_FORMAT
      ),
      scale_type: AGGREGATION_TYPE.WEEK,
    }
    const lazyData = await store.dispatch('milestones/getData', filters.value)
    response.value = {
      scale_type: 'week',
      data: deepMergeMilestoneData(response.value.data, lazyData.data),
    }
    lazyLoading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    lazyLoading.value = false
  }
}

const loadPrevYear = async () => {
  lazyLoading.value = true
  firstDay.value = format(
    subISOWeekYears(new Date(firstDay.value), 1),
    FILTERS_DATE_FORMAT
  )
  await nextTick(() => {
    const allElements = Array.from(
      document.querySelectorAll('.milestones-table th span')
    ).filter((el) =>
      el.textContent?.includes(`'${format(new Date(firstDay.value), 'yy')}`)
    )
    const lastElementOfPrevYear = allElements[allElements.length - 1]
    lastElementOfPrevYear?.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'auto',
    })
  })
  try {
    filters.value = {
      ...filters.value,
      since: format(
        new Date(startOfISOWeekYear(new Date(firstDay.value))),
        FILTERS_DATE_FORMAT
      ),
      until: format(
        new Date(endOfISOWeekYear(new Date(firstDay.value))),
        FILTERS_DATE_FORMAT
      ),
      scale_type: AGGREGATION_TYPE.WEEK,
    }
    const lazyData = await store.dispatch('milestones/getData', filters.value)
    response.value = {
      scale_type: 'week',
      data: deepMergeMilestoneData(lazyData.data, response.value.data),
    }
    lazyLoading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    lazyLoading.value = false
  }
}

const togglePopups = (value: {
  popup: PopupType
  data: CalendarWeekPopupData | MilestonePopupData
}) => {
  calendarWeekPopupData.value = null
  updateCalendarWeekPopup.value++
  nextTick(() => {
    calendarWeekPopupData.value = value.data
  })
}

const showCalendarWeekPopup = (data: string | DateColumnData) => {
  let calendarData
  if (typeof data === 'string') {
    calendarData = tableData.value[0][data]
  } else {
    calendarData = data
  }
  const calendarWeekData = {
    date: calendarData.date,
    project_id: calendarData.original_project_id,
    project_name: calendarData.project_name,
    filters: {
      projects: [calendarData.original_project_id],
      since: format(
        subWeeks(new Date(calendarData.date), 2),
        FILTERS_DATE_FORMAT
      ),
      until: format(
        addWeeks(new Date(calendarData.date), 2),
        FILTERS_DATE_FORMAT
      ),
    },
  }
  if (props.popup) {
    emit('close-calendar-week-popup', {
      popup: MILESTONE_POPUPS.CALENDAR_WEEK,
      data: calendarWeekData,
    })
  } else {
    calendarWeekPopupData.value = calendarWeekData
  }
}

const showMilestonePopup = (data: ExtendedReleaseItem) => {
  milestonePopupData.value = {
    release_name: data.name,
    project_id: data.project_id,
    project_name: data.project_name,
    release_id: data.id,
    filters: {
      projects: [data.project_id],
    },
  }
}

const clickOnReleases = (data: {
  event: Event
  releases: ExtendedReleaseItem[]
}) => {
  if (!data.releases[0].id) return
  if (!props.projectPage && data.releases?.length > 1) {
    releasesContextMenuOptions.value = data.releases.map((release) => ({
      label: release.name,
      id: release.id,
      command: () => {
        releasesContextMenuOptions.value = null
        showMilestonePopup(release)
      },
    }))
    releasesContextMenu.value.show(data.event)
  } else {
    showMilestonePopup(data.releases?.[0])
  }
}

const scrollToCurrentWeek = (behavior: 'auto' | 'smooth') => {
  const currentWeekElement = document.querySelector(
    '.milestones-table th:has(.current-week)'
  )
  const tableWrapper = document.querySelector('.p-datatable-wrapper')
  const widthOfUsualColumn = 64
  const widthOfFrozenColumn = 200

  if (currentWeekElement && tableWrapper) {
    tableWrapper?.scrollTo({
      top: 0,
      left:
        currentWeekElement?.offsetLeft -
        widthOfUsualColumn -
        widthOfFrozenColumn,
      behavior: behavior,
    })
  }
}

function scrollLazyLoading(e: any) {
  if (e.target?.scrollWidth - e.target?.clientWidth === e.target?.scrollLeft) {
    loadNextYear()
  }
  if (e.target?.scrollLeft === 0) {
    loadPrevYear()
  }
}

watch([milestonesTable], () => {
  tableWrapper.value = document.querySelector(
    '.milestones-table .p-datatable-wrapper'
  )
  tableWrapper.value?.addEventListener('scroll', scrollLazyLoading)
  if (
    milestonesTable.value &&
    !props.popup &&
    !milestonePopupData.value &&
    !calendarWeekPopupData.value
  ) {
    scrollToCurrentWeek('auto')
  }
})

watch([reloadMilestone], async () => {
  lazyLoading.value = true
  try {
    filters.value = {
      ...filters.value,
      since: firstDay.value,
      until: lastDay.value,
      scale_type: AGGREGATION_TYPE.WEEK,
    }
    response.value = await store.dispatch('milestones/getData', filters.value)
    lazyLoading.value = false
  } catch (e) {
    if (e instanceof Error) {
      fetchError.value = e?.message
    }
    lazyLoading.value = false
  }
})

onUnmounted(() => {
  tableWrapper.value?.removeEventListener('scroll', scrollLazyLoading)
})
</script>

<style lang="scss">
.milestones-table.p-datatable {
  height: 100%;
  .p-datatable-loading-overlay {
    z-index: 10;
  }
  .p-datatable-wrapper {
    height: 100%;
    table {
      height: 100% !important;
      .p-datatable-thead {
        z-index: 3;
        tr {
          th {
            height: 48px;
            display: flex;
            align-items: center;
            border-right: 1px solid #dcdcdc;
            padding: 0;
            .p-column-header-content {
              display: flex;
              align-items: center;
              height: 100%;
              width: 100%;

              &:not(:first-child) {
                justify-content: center;
              }

              .header-cell {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .p-datatable-tbody {
        height: 100%;
        display: flex;
        flex-direction: column;
        tr {
          flex-grow: 1;
        }
        tr:hover {
          background: white;
        }
        td {
          height: 100%;
          min-height: 32px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0;
          border-right: 1px solid #dcdcdc;
          &:not(:first-child) {
            position: relative;
          }
          &:first-child {
            z-index: 2;
            border-bottom: none;
            cursor: default;
            background: white;
          }

          .cell {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
          }
          .releases {
            &:hover .release-dot {
              transform: scale(1.2);
            }
            .release-dot {
              transition: transform 0.2s;
            }
            .release-dot:not(:first-child) {
              margin-left: -8px;
            }
          }
        }
      }
    }
  }

  &.project-page {
    .p-datatable-wrapper {
      .p-datatable-thead {
        tr {
          th:not(:first-child) {
            cursor: pointer;
          }
        }
      }
      .p-datatable-tbody {
        tr {
          td {
            cursor: default;
            border-bottom: none;
          }
        }
      }
    }
  }

  &.project-page:not(.no-releases) {
    .p-datatable-tbody {
      tr:not(.p-datatable-emptymessage):hover {
        td:first-child {
          box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }
        .cell {
          box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
