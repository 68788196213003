<template>
  <DataTable
    :value="tableData"
    :scrollable="scrollable"
    :scroll-height="customHeight ? customHeight : '344px'"
    class="p-datatable-sm custom"
    v-model:selection="selectionValue"
    v-model:expandedRows="expandedRows"
    removableSort
    @rowClick="(ev) => $emit('row-click', ev)"
    @rowExpand="(ev) => $emit('row-expand', ev)"
    :loading="lazyLoading"
    @sort="(ev) => $emit('sort', ev)"
    :virtualScrollerOptions="{
      lazy: lazyTable,
      onLazyLoad: (ev) => $emit('load', ev),
      itemSize: 43,
    }"
    :filters="filters"
    :globalFilterFields="filterFields"
    :row-class="() => 'group'"
  >
    <template #empty> No records found </template>
    <Column
      v-if="expander"
      :expander="expander"
      bodyStyle="flex-grow:0; min-width: 40px"
      headerStyle="flex-grow:0; min-width: 40px"
    />
    <Column
      v-if="selectionMode"
      selectionMode="multiple"
      bodyStyle="flex-grow:0; flex-basis:30px"
      headerStyle="flex-grow:0; flex-basis:30px"
    ></Column>
    <Column
      v-for="(col, i) of columnsData"
      :key="`${col.field}-${i}`"
      :field="col.field"
      :header="col.header"
      :sortable="col.is_sortable"
      :body-style="['minWidth: 30px', col.styles]"
      :header-style="col.styles"
      :body-class="col.classes"
      :header-class="col.classes"
    >
      <template #body="slotProps">
        <slot
          v-if="col.use_template"
          :name="col.field"
          :data="slotProps.data"
        ></slot>
        <div v-else class="truncate">
          {{ slotProps.data[col.field] }}
        </div>
      </template>
    </Column>
    <template #expansion="slotProps">
      <slot name="expansion" :data="slotProps"></slot>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable/DataTable.vue'
import Column from 'primevue/column/Column.vue'
import { defineProps, defineEmits, computed, ref } from 'vue'

const props = defineProps<{
  tableData: any
  columnsData: any
  filters?: any
  filterFields?: string[]
  lazyTable?: boolean
  customHeight?: string
  // table component doesn't render elements inside tbody tag in the tests files when scrollable is true
  scrollable?: boolean
  lazyLoading?: boolean
  selection?: any
  selectionMode?: string
  expander?: boolean
}>()

const emit = defineEmits<{
  (e: 'load', value: { first: number; last: number }): void
  (e: 'sort', value: any): void
  (e: 'row-click', value: any): void
  (e: 'row-expand', value: any): void
  (e: 'update:selection', value: any): void
}>()

const selectionValue = computed({
  get() {
    return props.selection
  },
  set(value: any) {
    emit('update:selection', value)
  },
})

const expandedRows = ref([])
</script>
